import axios from 'axios'
import getConfig from 'next/config'
import { MakeObserverLog } from './observerLogging';

const { publicRuntimeConfig } = getConfig()


export async function getCustomer() {
  // don't run server-side
  if (typeof window === 'undefined') {
    return undefined
  }
  let querySlug;
  // check if site is in dev mode or on staging in Vercel
  if (
    ((process.env.NODE_ENV === 'development') ||
    window.location.hostname.includes('appsembler.vercel.app') ||
    window.location.hostname.includes('testing-launcher.vercel.app')) &&
    publicRuntimeConfig.devKlantenSlug
  ) {
    await MakeObserverLog({
      type: 'event',
      message: 'Attempting to load Launcher using DEV_KLANTEN_SLUG.',
      customer_id: '',
      payload: { hostname: window?.location?.hostname, devKlantenSlug: publicRuntimeConfig.devKlantenSlug },
    });
    querySlug = publicRuntimeConfig.devKlantenSlug;
  } else {
    querySlug = publicRuntimeConfig.customersConfigs[window.location.hostname].KLANTEN_SLUG;
  }
  const fetchedCustomerInfo = await axios.get(`/api/klanten-proxy?slug=${querySlug}`);
  return fetchedCustomerInfo.data;
}