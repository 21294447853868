import AppLayout from "components/ui/AppLayout";
import ErrorMessage from "components/ui/ErrorMessage";
import LoadingMessage from "components/ui/LoadingMessage";
import Immutable from "immutable";
import { getCustomer } from "lib/getCustomer";
import { MakeObserverLog } from "lib/observerLogging";
import React from "react";
import { setAppCustomer, setAppThemeColor } from "store/actions/actions";
import Context from "./context";

export default function CustomerWrapper ({ children }) {

  const context = React.useContext(Context);

  // Helper flag for unfound customer
  const [isCustomer, setIsCustomer] = React.useState(null);

  const setTheme = () => {
    const appTheme = Immutable.fromJS({
      primaryColor: '#0090c1',
    })
    context.dispatch(setAppThemeColor('#0090c1'));
  }

  React.useEffect(() => {

    async function checkCustomer() {
      let customerData;
      try {
        customerData = await getCustomer();
      } catch (error) {
        await MakeObserverLog({
          session: undefined,
          type: 'error',
          message: 'Problem in obtaining customer data from Klanten.',
          customer_id: undefined,
          payload: { error: error, hostname: window?.location?.hostname },
        })
      }
      if (!customerData || !customerData.length) {
        // handle unfound Customer
        setIsCustomer(false);
        await MakeObserverLog({
          session: undefined,
          type: 'event',
          message: 'No customer found for Dashboard on accessed URL.',
          customer_id: '',
          payload: { hostname: window?.location?.hostname },
        })
        return;
      } else {
        context.dispatch(setAppCustomer(customerData[0]));
        setIsCustomer(true);
      }
    }

    checkCustomer();
    setTheme();
  }, [])

  if (isCustomer === null) {
    return (
      <AppLayout>
        <LoadingMessage message={`Loading the Lab Launcher...`} />
      </AppLayout>
    )
  }

  if (isCustomer === false) {
    return (
      <AppLayout>
        <ErrorMessage message={`Organization subdomain not found.`} />
      </AppLayout>
    )
  }

  return (
    <>{children}</>
  )
}