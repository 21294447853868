import getConfig from 'next/config'
import axios from 'axios'

const { publicRuntimeConfig } = getConfig()

export async function MakeObserverLog({session, type = 'Error', message, customer_id = '-', payload}) {

  if (!(publicRuntimeConfig.observerUrl && publicRuntimeConfig.observerClientId && publicRuntimeConfig.observerSourceApp)) {
    console.info('Observer not configured. Falling back to regular logging.');
    console.log(`${type}:`, {
      'message': message,
      'fullPayload': payload
    });
    return { error: 'Observer logging error' };
  }

  // Log stuff to Console as well when developing locally for ease of dev work
  if (process.env.NODE_ENV === 'development') {
    console.info('Observer log created.');
    console.log(`${type}:`, {
      'message': message,
      'fullPayload': payload
    });
  }

  const postPayload = {
    type: type,
    message: message,
    source_app: publicRuntimeConfig.observerSourceApp,
    acknowledged: false,
    acknowledged_timestamp: null,
    acknowledged_by: '',
    customer_id: customer_id,
    user_authenticated: session ? true : false,
    user: session?.user ? {
      applicationId: session.user.applicationId,
      email: session.user.email,
      fullName: session.user.fullName,
      username: session.user.name,
      id: session.user.id,
      role: session.user.role,
      tenantId: session.user.tenantId,
    } : {},
    payload: payload,
  }
  const appsemblerServicesEnvironment = publicRuntimeConfig.customersConfigs[window.location.hostname].ENVIRONMENT;
  try {
    const response = await axios.post(
      `${publicRuntimeConfig.observerUrl}/v1/environment/${appsemblerServicesEnvironment}/logs`,
      postPayload,
      {
        headers: {
          'X-Observer-Client': publicRuntimeConfig.observerClientId,
        }
      }
    );
    return response;
  } catch (error) {
    console.log('Problem in sending the log to Observer.', error);
    console.log('Original error:', {
      'message': message,
      'fullError': payload
    });
    return { error: 'Observer logging error' };
  }

}