import Immutable from "immutable"
import * as ACTION_TYPES from "../actions/actionTypes"

export const initialState = Immutable.fromJS({
  isAuthenticated: false,
  user: {},
  userLabs: [],
  customer: {},
  appSettings: {
    theme: {
      mode: 'dark',
      text: '#fff',
      icons: '#fff',
      background: '#000',
      primaryColor: '#0090c1',
    }
  },
})

export const AppReducer = (state = initialState, action) => {

  switch (action.type) {

    case ACTION_TYPES.SET_APP_CUSTOMER:
      return (
        state.setIn(['customer'], Immutable.fromJS(action.payload))
      )

    case ACTION_TYPES.SET_APP_THEME_COLOR:
      return (
        state.setIn(['appSettings', 'theme', 'primaryColor'], Immutable.fromJS(action.payload))
      )

    case ACTION_TYPES.SET_USER_LABS:
      return (
        state.setIn(['userLabs'], Immutable.fromJS(action.payload))
      )

    case ACTION_TYPES.SET_DARK_THEME:
      return (
        state
          .setIn(['appSettings', 'theme', 'mode'], 'dark')
          .setIn(['appSettings', 'theme', 'text'], '#fff')
          .setIn(['appSettings', 'theme', 'icons'], '#fff')
          .setIn(['appSettings', 'theme', 'background'], '#000')
      )

    case ACTION_TYPES.SET_LIGHT_THEME:
      return (
        state
          .setIn(['appSettings', 'theme', 'mode'], 'light')
          .setIn(['appSettings', 'theme', 'text'], '#000')
          .setIn(['appSettings', 'theme', 'icons'], '#000')
          .setIn(['appSettings', 'theme', 'background'], '#fff')
      )

    default:
      return state

  }

}