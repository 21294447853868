import axios from 'axios'
import getConfig from 'next/config'


const { publicRuntimeConfig } = getConfig()


export async function launchSecureLab({ projectName }) {

  try {
    const response = await axios('/api/labs-v1/secure-launch',  {
      method: 'POST',
      data: {
        project: projectName,
      }
    })
    return response.data;
  } catch (error) {
    console.error('There was an issue in launching the secure lab.')
  }

}

export async function restartSecureLab({ instanceId }) {

  try {
    const response = await axios('/api/labs-v1/secure-restart',  {
      method: 'POST',
      data: {
        instanceId: instanceId,
      }
    })
    return response.data;
  } catch (error) {
    console.error('There was an issue in restarting the secure lab.')
  }

}

export async function launchStandardLab({ projectName, projectToken, userEmail }) {

  try {
    const response = await axios('/api/labs-v1/standard-launch',  {
      method: 'POST',
      data: {
        project: projectName,
        owner_email: userEmail,
        token: projectToken,
      }
    })
    return response.data;
  } catch (error) {
    console.error('There was an issue in launching the standard lab.')
  }

}

export async function getLabDetailsV1({ projectId }) {

  try {
    const response = await axios('/api/labs-v1/lab-info', {
      method: 'POST',
      data: {
        labId: projectId
      }
    });
    return response.data;
  } catch (error) {
    console.error('There was an issue fetching lab details.')
  }

}

export async function listUserLabs() {

  try {
    const response = await axios('/api/labs-v1/user-labs', {
      method: 'GET'
    });
    return response.data;
  } catch (error) {
    console.error('There was an issue searching user-attributed labs.')
  }

}

export function checkIfUserInstanceExists(labId, userLabs) {
  const foundIndex = userLabs.findIndex((instance) => instance.get('project') === labId);
  if (foundIndex !== -1) {
    // found an existing attributed instance
    return userLabs.get(foundIndex);
  } else {
    return null;
  }
}

export async function setLabJwt({ deployId }) {
  const avlUrl = publicRuntimeConfig.customersConfigs[window.location.hostname].AVL_URL;
  try {
    const jwtResponse = await axios('/api/labs-v1/generate-labs-jwt',  {
      method: 'GET',
    })
    const setResponse = await axios(`${avlUrl}/authorize_learner/`,  {
      method: 'POST',
      data: {
        deploy_id: deployId,
        learner_jwt: jwtResponse.data.jwt,
      }
    })
    return true;
  } catch (error) {
    console.error('There was an issue in setting the JWT.')
    return false;
  }

}