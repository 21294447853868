import { SessionProvider } from 'next-auth/react'
import React from 'react'
import { ContextStateWrapper } from 'store/ContextConfig'
import CustomerWrapper from 'wrappers/CustomerWrapper'
import UserWrapper from 'wrappers/UserWrapper'
import '../styles/globals.css'
import IframeAuthWrapper from '../wrappers/IframeAuthWrapper'


export default function LauncherApp({ Component, pageProps: { session, ...pageProps } }) {

  const [showChild, setShowChild] = React.useState(false);

  React.useEffect(() => {
    setShowChild(true);
  }, [])

  if (!showChild) {
    return null;
  }

  return (
    <ContextStateWrapper>
      <SessionProvider session={session}>
        <CustomerWrapper>
          <UserWrapper>
            <IframeAuthWrapper>
              <Component {...pageProps} />
            </IframeAuthWrapper>
          </UserWrapper>
        </CustomerWrapper>
      </SessionProvider>
    </ContextStateWrapper>
  )
}