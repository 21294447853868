import React from "react";
import AppLayout from "components/ui/AppLayout";
import LoadingMessage from "components/ui/LoadingMessage";
import { detectBrowser, handleCookieAccess } from "lib/iframeUtils";
import { useSession } from "next-auth/react";

//check if Storage Access API is supported by the browser and has access otherwise check if cookies are enabled
const hasCookiesEnabled = async () => {
  if (document.hasStorageAccess && document.requestStorageAccess) {
    return await document
      .hasStorageAccess()
      .then((hasAccess) => hasAccess)
      .catch((_) => false);
  }
  return window.navigator.cookieEnabled;
};

export default function IframeAuthWrapper({ children }) {

  const browser = detectBrowser();

  const { status: authStatus, data: session } = useSession();

  const [checking, setChecking] = React.useState(true);
  const [hasCookieAccess, setHasCookieAccess] = React.useState(false);

  // open new tab or popup window for oauth if we have to set cookies in first party context. notify user if we are unable to open new tab
  const handleLogin = () => {
    const newWindow = window.open(
      `${window.location.origin}/login`,
      '_blank'
    );
    try {
      newWindow.focus();
    } catch {
      alert(
        'Pop-up Blocker is enabled! Please add this site to your exception list.'
      );
      //or change state to display something different on the page
    }
  };

  React.useEffect(() => {
    async function checkCookieAccess() {
      await hasCookiesEnabled().then((enabled) => {
        if (enabled) {
          setHasCookieAccess(true);
        }
      });
      setChecking(false);
    }
    checkCookieAccess();
  }, []);

  // RENDERS

  if (typeof window === 'undefined' || (authStatus === 'loading') || checking) {
    return (
      <AppLayout>
        <LoadingMessage message={'Successfully signed you in. Please wait...'} />
      </AppLayout>
    );
  }

  //if this page was opened by our iframe we were able to set cookies. so we have to reload the iframe and the user is logged in. finally close the tab.
  // if (window.opener && authStatus === 'authenticated') {
  //   window.opener.location.reload();
  //   window.close();
  //   return (
  //     <div>
  //       <main>
  //         <p>
  //           <code>Loading...</code>  
  //         </p>
  //       </main>
  //     </div>
  //   );
  // }

  //if we have no cookie access let the user do an interaction to allow cookies
  // if (!hasCookieAccess) {
  //   return (
  //     <div>
  //       <main>
  //         <div>
  //           <button
  //             onClick={handleCookieAccess}
  //           >
  //             <h2>Request Storage Access &rarr;</h2>
  //             <p>{`We need cookies to work! Please click here to allow`}</p>
  //           </button>
  //         </div>
  //       </main>
  //     </div>
  //   );
  // }

  //if the user is not logged in check if we are not in an iframe or that the browser dont need first party context then redirect inside the iframe else let the user do an interaction to open oauth in new tab
  // if (!session) {
  //   if (
  //     window.top === window.self ||
  //     !['Safari', 'Firefox', 'Edge', 'Unknown'].includes(browser) //In this browsers we have to set cookies in first-party context, therefore we are not able to redirect without user interaction
  //   ) {
  //     // if not in iFrame
  //     return <div>{children}</div>;
  //   } else {
  //     return (
  //       <div>
  //         <main>
  //           <p>
  //             We need you to sign into the Labs app:
  //           </p>
  //           <div>
  //             <button
  //               onClick={handleLogin}
  //             >
  //               <h2>Sign in &rarr;</h2>
  //               <p>{`Let's do this!`}</p>
  //             </button>
  //           </div>
  //         </main>
  //       </div>
  //     )
  //   }
  // }

  return (
    <div>
      {children}
    </div>
  )
}