import AppLayout from 'components/ui/AppLayout';
import LoadingMessage from 'components/ui/LoadingMessage';
import { listUserLabs } from 'lib/labFunctions';
import { useSession } from 'next-auth/react'
import React from 'react';
import { setUserLabs } from 'store/actions/actions';
import Context from './context';

export default function UserWrapper({ children }) {

  const context = React.useContext(Context);
  const { status: authStatus, data: session } = useSession();

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    async function getUserLabs() {
      try {
        const attributedLabs = await listUserLabs();
        context.dispatch(setUserLabs(attributedLabs));
        setLoading(false);
      } catch {
        console.error('Error in UserWrapper: failed to set existing attributed user labs into Context.')
      }
    }
    setLoading(true);
    if (session?.user) {
      getUserLabs();
    } else {
      setLoading(false);
    }
  }, [authStatus, session])

  if (loading) {
    return (
      <AppLayout>
        <LoadingMessage message='Loading attributed labs...' />
      </AppLayout>
    )
  }

  return (
    <>{children}</>
  )
}