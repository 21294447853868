// inIframe() function
// returns true if the app is displayed in an iFrame, false otherwise
// returns null if no browser
export function inIframe() {

  if (typeof window === 'undefined') return null;

  return window.top !== window.self;

}


// detectBrowser() function
// detects the browser
// can be replaced with a library, but no need to install additional packages just for this
// returns null if no browser
export function detectBrowser() {

  if (typeof window === 'undefined') return null;

  let browser;
  const userAgent = window.navigator.userAgent;
  if (userAgent.indexOf('Firefox') > -1) {
    browser = 'Firefox';
  } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
    browser = 'Opera';
  } else if (userAgent.indexOf('Trident') > -1) {
    browser = 'Internet Explorer';
  } else if (userAgent.indexOf('Edge') > -1) {
    browser = 'Edge';
  } else if (userAgent.indexOf('Chrome') > -1) {
    browser = 'Chrome';
  } else if (userAgent.indexOf('Safari') > -1) {
    browser = 'Safari';
  } else {
    browser = 'Unknown';
  }

  return browser;

};


// check if we can request storage access else notify the user to allow cookies via settings
export async function handleCookieAccess() {
  if (document.requestStorageAccess) {
    await document.requestStorageAccess().then(
      () => {
        // now we have access and reload the page to see if we have a session or have to login
        location.reload();
      },
      () => {
        alert('Cookie access denied. Please allow!');
        //or change state to display something on the page
      }
    )
  } else {
    alert('Please allow cookies in your settings.');
  }
}


// open launcher in new tab and focus
export function openLauncherInNewTab() {
  const newWindow = window.open(
    `${window.location}`,
    '_blank'
  );
  try {
    newWindow.focus();
  } catch {
    alert(
      'Pop-up Blocker is enabled! Please add this site to your exception list.'
    );
  }
}