import * as ACTION_TYPES from './actionTypes'

export const setAppCustomer = (data) => {
  return {
    type: ACTION_TYPES.SET_APP_CUSTOMER,
    payload: data,
  }
}

export const setAppThemeColor = (theme) => {
  return {
    type: ACTION_TYPES.SET_APP_THEME_COLOR,
    payload: theme,
  }
}

export const setUserLabs = (labs) => {
  return {
    type: ACTION_TYPES.SET_USER_LABS,
    payload: labs,
  }
}

export const setDarkTheme = () => {
  return {
    type: ACTION_TYPES.SET_DARK_THEME,
  }
}

export const setLightTheme = () => {
  return {
    type: ACTION_TYPES.SET_LIGHT_THEME,
  }
}