import styled from "styled-components"

const StyledWrapper = styled.div`
  position: relative;

  &:after {
    display: none;
    width: 120%;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-top: 8px;
    margin-left: -70%;
    background-color: rgba(50, 50, 50, 0.6);
    border-radius: 5px;
    color: #fff;
    font-size: 11px;
    text-align: center;
    padding: 3px 5px;
    content: '${props => props.message}';
  }

  &:hover {

    &:after {
      display: block;
    }
  }
`

export default function Popover({ children, message }) {

  return (
    <StyledWrapper message={message}>
      {children}
    </StyledWrapper>
  )
}