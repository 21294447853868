import Popover from "components/elements/Popover"
import ArrowBackIcon from "components/icons/ArrowBack"
import DarkModeIcon from "components/icons/DarkMode"
import LightModeIcon from "components/icons/LightMode"
import NewTabIcon from "components/icons/NewTab"
import SignInIcon from "components/icons/SignIn"
import SignOutIcon from "components/icons/SignOut"
import { inIframe, openLauncherInNewTab } from "lib/iframeUtils"
import { signIn, signOut, useSession } from "next-auth/react"
import Link from "next/link"
import React from "react"
import { setDarkTheme, setLightTheme } from "store/actions/actions"
import styled from "styled-components"
import Context from "wrappers/context"

const StyledWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  background-color: ${props => props.background};
  display: grid;
  grid-template-rows: auto 1fr auto;
  color: ${props => props.textColor};
`

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 15px;
  color: ${props => props.textColor};
  position: relative;
  z-index: 2;
`

const HeaderLeftArea = styled.div`
  margin-right: auto;
  display: flex;
  align-items: center;
`

const BackNavContainer = styled.div`
  margin-right: 20px;
  &:hover {
    opacity: 0.7;
  }
`

const StyledChildrenContainer = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  position: relative;
  z-index: 1;
`

const IconButton = styled.button`
  display: inline-flex;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  border: none;
  outline: none;
  align-items: center;
  justify-content: center;
  background: none;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  margin-left: 20px;

  &:hover {
    background-color: ${props => props.darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'};
    opacity: 0.8;
    cursor: pointer;
  }
`

const UserAvatar = styled.img`
  display: block;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  margin-right: 10px;
`

const StyledFooter = styled.div`
  display: block;
  padding: 20px;
  font-size: 11px;
  opacity: 0.6;
  text-align: center;
  color: ${props => props.textColor};
`

const StyledLabName = styled.div`
  display: block;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 900;

  span {
    font-size: 18px;
    font-weight: 900;
    margin-left: 5px;
  }
`

export default function AppLayout({ children, labTitle, enableBackNav }) {

  const { status: authStatus, data: session } = useSession();
  const context = React.useContext(Context);

  return (
    <StyledWrapper
      background={context.appSettings.getIn(['theme', 'background'])}
      textColor={context.appSettings.getIn(['theme', 'text'])}
    >
      <StyledHeader
        textColor={context.appSettings.getIn(['theme', 'text'], '#fff')}
      >
        <HeaderLeftArea>
          {(enableBackNav && !inIframe()) ? (
            <BackNavContainer>
              <Link href='/'>
                <ArrowBackIcon
                  color={context.appSettings.getIn(['theme', 'icons'])}
                  size={32}
                />
              </Link>
            </BackNavContainer>
          ) : ''}
          {labTitle ? (
            <StyledLabName>
              Lab: <span>{labTitle}</span>
            </StyledLabName>
          ) : ''}
        </HeaderLeftArea>
        {inIframe() ? (
          <Popover message='Open in new tab'>
            <IconButton
              darkMode={(context.appSettings.getIn(['theme', 'mode']) === 'dark')}
              onClick={() => openLauncherInNewTab()}
            >
              <NewTabIcon color={context.appSettings.getIn(['theme', 'icons'])} />
            </IconButton>
          </Popover>
        ) : ''}
        {(!inIframe() && !session?.user) ? (
          <Popover message='Sign in'>
            <IconButton
              darkMode={(context.appSettings.getIn(['theme', 'mode']) === 'dark')}
              onClick={() => signIn('appsembleridp')}
            >
              <SignInIcon color={context.appSettings.getIn(['theme', 'icons'])} />
            </IconButton>
          </Popover>
        ) : ''}
        {session?.user ? (
          <Popover
            message={session.user.fullName}
          >
            <UserAvatar
              src={session.user.imageUrl}
              alt={session.user.fullName}
            />
          </Popover>
        ) : ''}
        {session?.user ? (
          <Popover message='Sign out'>
            <IconButton
              darkMode={(context.appSettings.getIn(['theme', 'mode']) === 'dark')}
              onClick={() => signOut()}
            >
              <SignOutIcon color={context.appSettings.getIn(['theme', 'icons'])} />
            </IconButton>
          </Popover>
        ) : ''}
        <Popover message={(context.appSettings.getIn(['theme', 'mode']) === 'dark') ? 'Toggle light mode' : 'Toggle dark mode'}>
          <IconButton
            darkMode={(context.appSettings.getIn(['theme', 'mode']) === 'dark')}
            onClick={(context.appSettings.getIn(['theme', 'mode']) === 'dark') ? () => context.dispatch(setLightTheme()) : () => context.dispatch(setDarkTheme())}
          >
            {(context.appSettings.getIn(['theme', 'mode']) === 'dark') ? (
              <LightModeIcon color={context.appSettings.getIn(['theme', 'icons'])} />
            ) : (
              <DarkModeIcon color={context.appSettings.getIn(['theme', 'icons'])} />
            )}
          </IconButton>
        </Popover>
      </StyledHeader>
      <StyledChildrenContainer>
        {children}
      </StyledChildrenContainer>
      <StyledFooter
        textColor={context.appSettings.getIn(['theme', 'text'], '#fff')}
      >
        © Appsembler {new Date().getFullYear()}. All rights reserved.
      </StyledFooter>
    </StyledWrapper>
  )
}