import React from "react";
import * as AppReducer from 'store/reducers'
import Context from "wrappers/context";

export function ContextStateWrapper({ children }) {

  // Map the state and dispatch to our AppReducer
  const [state, dispatch] = React.useReducer(
    AppReducer.AppReducer,
    AppReducer.initialState
  )
  
  return (
    // Create the provider wrapper
    <Context.Provider
      value={{
        isAuthenticated: state.get('isAuthenticated'),
        user: state.get('user'),
        customer: state.get('customer'),
        appSettings: state.get('appSettings'),
        userLabs: state.get('userLabs'),
        dispatch: dispatch,
      }}
    >
      {children}
    </Context.Provider>
  )

}

export function useAppContext() {
  return React.useContext(ContextStateWrapper)
}